<template>
  <Page class="pb-40">
    <EstimatingDefaultsBody :id="$store.state.session.company.company_id" />
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page'
import EstimatingDefaultsBody from '../bodies/EstimatingDefaults.vue'

export default {
  mixins: [PageMixin],
  name: 'EstimatingDefaults',
  components: {
    EstimatingDefaultsBody
  },
  mounted() {},
  beforeUnmount() {}
}
</script>

<style lang="scss" rel="stylesheet/scss">
.card {
  width: 100%;
}
</style>
