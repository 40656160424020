<template>
  <Page title="Estimating settings" icon="calculator">
    <save-bar :loading="loading" :isDirty="isDirty" @save="saveChanges" @cancel="reset"> </save-bar>
    <CompanyEstimatingSettings
      v-model="subComponentInterface"
      :trackChanges="false"
      :key="refId"
      :reference="refId"
    />
  </Page>
</template>

<script>
import SaveBar from '../pages/subnav/SaveBar.vue'
import ObjectManipulator from '../mixins/ObjectManipulator'
import BodyMixin from '../mixins/Body'
import CompanyEstimatingSettings from '../company/CompanyEstimatingSettings.vue'
import eventBus from '../../eventBus'

export default {
  mixins: [ObjectManipulator('company', true), BodyMixin],
  name: 'EstimatingDefaults',

  components: {
    SaveBar,
    CompanyEstimatingSettings
  },

  props: {
    id: {
      required: true
    },
    forceFetch: {
      default: true
    },
    dirtyCheckDelay: {
      default: 500
    }
  },
  mounted() {
    // This object is part of the session variables
    // so we need to update session variables when this is saved/changed
    eventBus.$on(`${this.uid}-saved`, () => this.$store.dispatch('getBaseValues', { cloak: false }))
  },
  beforeUnmount() {
    eventBus.$off(`${this.uid}-saved`)
  }
}
</script>

<style lang="scss" rel="stylesheet/scss"></style>
